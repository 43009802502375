/*
  reference:
  https://stackoverflow.com/questions/61299461/creating-a-mock-terminal-with-a-loop-to-delay-css-animations
*/
.wrapper {
  display: grid;
  place-items: left;
  width: 100%;
  margin: 20px;
  
  margin-left: auto;
  margin-right: auto;
}

.stdin-show {
  display: inline-flex;
  overflow-wrap: break-word;
  white-space: initial;
  margin-top: .1em;
}

.stdout-show {
  overflow-wrap: break-word;
  white-space: initial;
  margin-top: .1em;
}

.hide {
  display: none
}

.char-done {
  display: inline-flex;
}

.space-done {
  display: inline-flex;
  margin-right: .45em;
}

.typing-char {
  white-space: nowrap;
  overflow: hidden;
  border-right: .45em solid #999;
/*  transform: translateY(-50%);*/
  animation: typing 0.05s steps(1, end);
  display: inline-flex ;
}

.typing-space {
  white-space: nowrap;
  overflow: hidden;
  width: .45em;
  border-right: .45em solid #999;
  animation: typing 0.05s steps(1, end);
  display: inline-flex ;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}



/*cursor*/

.cursor {
  display: inline-flex;
  margin-right: .4em;
}

.cursor-init::after {
    content: "II";
    background: #fff;
    color: white;
    display: inline-block;
    margin-left: .4em;
    animation: cursor-blink 0.8s steps(2) 3;
}

.cursor-wait::after {
    content: "II";
    background: #fff;
    color: white;
    display: inline-block;
    margin-left: .4em;
    animation: cursor-blink 0.8s steps(2) 2;
}

.cursor-infinite::after {
    content: "II";
    background: #fff;
    color: white;
    display: inline-block;
    margin-left: .4em;
    animation: cursor-blink 0.8s steps(2) infinite;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

