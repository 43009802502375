.App {
  text-align: center;
}

.background {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  top: 0;
}

.App-link {
  color: #61dafb;
}


.stories {
  background-color: #282c34;
  position: fixed;
  
  color: white;

  left: 3px;
  right: 3px;
  bottom: 0;
}

